import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NavLogo from '../../../assets/logo-transparent-png.png';

const menuItems = [
  { name: "Who we are", url: "#whoweare" },
  { name: "What we do", url: "#whatwedo" },
  { name: "Why us?", url: "#whyus" }
];

const HomeNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Home");
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.profile.user);
  const location=useLocation()
  const userRole = user?.roles[0]?.name;
  const navigate = useNavigate();


  const scrollToSection = (id) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 300); 
  };

  const handleNavigation = (item) => {
    setActiveLink(item.name);
    setIsMenuOpen(false);

    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: item.url.substring(1) } });
    } else {
      scrollToSection(item.url.substring(1));
    }
  };
  useEffect(() => {
    if (location.state?.scrollTo) {
      scrollToSection(location.state.scrollTo);
    }
  }, [location]);


  const handleDashboardClick = () => {
    if (userRole === "superadmin") {
      navigate("/admin-dashboard");
    } else if (userRole === "recruiter") {
      navigate("/dashboard");
    } else if (userRole === "marketing") {
      navigate("/marketing-dashboard");
    } else {
      // Fallback or default dashboard
      navigate("/dashboard");
    }
  };

  return (
    <header className="home-navbar">
      <nav className="home-nav-container">
        <div className="nav-content">
          <a href="/" className="logo">
            <img src={NavLogo} alt="1800Hired Logo" />
          </a>
          <div className="menu-toggle">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="menu-button"
              aria-expanded={isMenuOpen}
            >
              {isMenuOpen ? (
                <svg className="icon" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  />
                </svg>
              ) : (
                <svg className="icon" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className={`menu ${isMenuOpen ? "open" : ""}`}>
            <ul className="menu-list">
              {menuItems.map((item) => (
                <li key={item.name}>
                  {item.url.startsWith("#") ? (
                    <a
                      href={item.url}
                      onClick={(e) => {
                        e.preventDefault();
                        handleNavigation(item);
                      }}
                      className={activeLink === item.name ? "active" : ""}
                    >
                      {item.name}
                    </a>
                  ) : (
                    <a
                      href={item.url}
                      onClick={() => setActiveLink(item.name)}
                      className={activeLink === item.name ? "active" : ""}
                    >
                      {item.name}
                    </a>
                  )}
                </li>
              ))}  
              {token ? (
                <li>
                  <a
                    className="home-link"
                    onClick={handleDashboardClick}
                    style={{ cursor: "pointer" }}
                  >
                    Go to Dashboard
                  </a>
                </li>
              ) : (
                <>
                  <li>
                    <a href="/register" className="home-link">
                      Register
                    </a>
                  </li>
                  <li>
                    <a href="/login" className="home-link">
                      Login
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HomeNav;
