import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import '../../../styles/globalStyle.css';
import Account from '../../../assets/user-02.png';
import LogOut from '../../../assets/Logout.png';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../services/operations/authAPI';
import AccountSetting from '../accountSetting/accountSetting';
import Bell from '../../../assets/bell.png'
import NotificationBell from './notificationBell';
import onBell from '../../../assets/notification.png'
import premiumtick from '../../../assets/guarantee.png'
import logoNav from '../../../assets/logoNav.png'
import { notificationList } from '../../../services/operations/uploadAPI';

const NavBar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [isBellModalOpen, setIsBellModalOpen] = useState(false);
  const [isBellClosing, setIsBellClosing] = useState(false);
  const [notifications, setNotifications] = useState({});

  const user = useSelector((state) => state.profile.user);
  const token = useSelector((state) => state.auth.token);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const bellModalRef = useRef(null);

  const handleOpenDropdown = () => {
    setShowDropdown((prev) => !prev);
  };


  const fetchNotifications = async () => {
    try {
      const response = await dispatch(notificationList(token));

      if (response?.data?.notifications) {
        setNotifications(response.data.notifications);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(() => fetchNotifications(), 30000);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, buttonRef]);

  useEffect(() => {
    const handleClickOutsideBell = (event) => {
      if (
        bellModalRef.current &&
        !bellModalRef.current.contains(event.target) &&
        !document.querySelector('.bell-icon').contains(event.target)
      ) {
        toggleBellModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutsideBell);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideBell);
    };
  }, [bellModalRef, isBellModalOpen]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout(token, navigate));
  };

  const handleOpenAccount = () => {
    setShowAccountModal(true);
    setShowDropdown(false);
  };

  const handleCloseAccountModal = () => {
    setShowAccountModal(false);
  };

  const toggleBellModal = () => {
    if (isBellModalOpen) {
      setIsBellClosing(true);
      setTimeout(() => {
        setIsBellModalOpen(false);
        setIsBellClosing(false);
      }, 800);
    } else {
      setIsBellModalOpen(true);
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-left">
          <h1 className="navbar-heading">
            <Link to="/dashboard"><img src={logoNav} alt='logo' width={35} height={35}/></Link>
          </h1>
          {/* <ul className="navbar-menu">
            {user?.roles[0]?.permissions
              ?.filter((permission) => permission.path !== "/dashboard")
              .map((permission, index) => (
                <li className="menu-item" key={index}>
                  <NavLink
                    to={permission.path}
                    end
                    className={({ isActive }) => (isActive ? 'active-link' : '')}
                  >
                    {permission.name}
                  </NavLink>
                </li>
              ))}
          </ul> */}
          <ul className="navbar-menu">
            {user?.roles[0]?.permissions?.map((permission, index) => (
              <li className="menu-item" key={index}>
                <NavLink
                  to={permission.path}
                  end
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  {permission.displayName}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="navbar-right">

          <img src={Object.keys(notifications).length > 0 ? onBell : Bell}
            width={30} height={30}
            alt="Notifications"
            className="bell-icon"
            onClick={toggleBellModal} />
          <div className='account-logo-premium' style={{
            marginBottom: user?.is_premium === true ? "14px" : "0px"
          }}>
            {user?.is_premium && <img src={premiumtick} alt="premium-tick" className='premium-avatar' />}
            <img
              src={user.avatar}
              alt="Logo"
              loading="lazy"
              className="navbar-logo"
              onClick={handleOpenDropdown}
              ref={buttonRef}
            />
          </div>

          {showDropdown && (
            <div className="modal-dropdown" ref={dropdownRef}>
              <div className="dropdown-profile">
                <div className="profile-left">
                  <img
                    src={user.avatar}
                    alt="Profile"
                    className="profile-logo"
                    loading="lazy"
                  />
                </div>
                <div className="profile-right">
                  <p className="profile-name">{user.name}</p>
                  <p className="profile-email">{user.email}</p>
                </div>
              </div>
              <ul className="dropdown-menu">
                <li className="dropdown-item" onClick={handleOpenAccount}>
                  <img src={Account} alt="Account" className="menu-icon" />
                  Account Settings
                </li>
                <li className="dropdown-item" onClick={handleLogout}>
                  <img src={LogOut} alt="Logout" className="menu-icon" />
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>

      {showAccountModal && <AccountSetting onClose={handleCloseAccountModal} isOpen={showAccountModal} />}
      {(isBellModalOpen || isBellClosing) && (
        <div ref={bellModalRef}>
          <NotificationBell
            isOpen={!isBellClosing}
            notifications={notifications}
            fetchNotifications={fetchNotifications}
            setNotifications={setNotifications}
            onClose={toggleBellModal} />
        </div>
      )}
    </>
  );
};

export default NavBar;

