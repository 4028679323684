import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import warning from '../../../assets/warning.png';
import { dashboardViewDownloadCount } from '../../../services/operations/ProfileAPI';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SpentChart = () => {
    const user = useSelector((state) => state.profile.user);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);

    
    const monthMap = {
        Jan: "January",
        Feb: "February",
        Mar: "March",
        Apr: "April",
        May: "May",
        Jun: "June",
        Jul: "July",
        Aug: "August",
        Sep: "September",
        Oct: "October",
        Nov: "November",
        Dec: "December",
    };
    
    const [chartData, setChartData] = useState({
        labels: Object.keys(monthMap),
        datasets: [
            {
                label: '',
                data: Array(12).fill(0),
                borderWidth: 0,
                borderRadius: 4,
                backgroundColor: user?.is_premium ? '#8B6DAD' : 'transparent',
                borderColor: user?.is_premium ? '#8B6DAD' : 'transparent',
                barThickness: user?.is_premium ? 22 : 0,
            },
        ],
    });

    const getDashboardViewDownloadCount = async () => {
        const response = await dispatch(dashboardViewDownloadCount(token));
        if (response?.data?.data?.monthly_stats) {
            const stats = response.data.data.monthly_stats;
            const earnings = chartData.labels.map(shortMonth => {
                const fullMonth = monthMap[shortMonth]; 
                return stats[fullMonth]?.earnings ?? 0;
            });
            setChartData(prevData => ({
                ...prevData,
                datasets: [
                    {
                        ...prevData.datasets[0],
                        data: earnings,
                        backgroundColor: user?.is_premium ? '#8B6DAD' : 'transparent',
                        borderColor: user?.is_premium ? '#8B6DAD' : 'transparent',
                        barThickness: user?.is_premium ? 22 : 0,
                    }
                ]
            }));
        }
    };

    useEffect(() => {
        getDashboardViewDownloadCount();
    }, []);

    const options = { 
        responsive: true,
        maintainAspectRatio: false,
        layout: { padding: 25 },
        plugins: {
            legend: { display: false },
            title: {
                display: true,
                text: 'Total Spent ($)',
                align: 'start',
                color: "#1A1A1A",
                font: { size: "20px", weight: 550 },
                padding: { top: 20, bottom: 30 },
            },
            tooltip: {
                enabled: user?.is_premium,
                callbacks: {
                    label: (tooltipItem) => `$${tooltipItem.raw}`,
                    title: () => '',
                },
                backgroundColor: 'black',
                titleColor: 'white',
                displayColors: false,
                padding: 13,
                bodyColor: 'white',
                bodyFont: { size: "14px", weight: "300" },
            },
        },
        scales: {
            x: {
                grid: { display: false },
                ticks: { color: '#999999', font: { size: "12px" } },
                border: { display: false },
            },
            y: {
                beginAtZero: true,
                suggestedMax: 50,
                ticks: { stepSize: 5, color: '#999999', font: { size: "12px" } },
                grid: { display: false },
                border: { display: false },
            },
        },
    };

    return (
        <div style={{ width: '100%', height: user?.is_premium ? "736px" : "524px", position: 'relative' }}>
            <Bar data={chartData} options={options} />
            {!user?.is_premium && (
                <div style={{
                    position: 'absolute', top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)', pointerEvents: 'none', width: "75%"
                }}>
                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        gap: '5px', color: '#999999', fontSize: '15px', textAlign: 'center', padding: '0 20px'
                    }}>
                        <img src={warning} alt="warning" style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
                        <span>Total spent can be viewed only by Premium members</span>
                    </div>
                </div>
            )}
        </div>
    );
}
export default SpentChart;