import React from 'react'
import CreditContent from '../common/creditSection'

const JobPosting = () => {
  return (
    <>
    <CreditContent/>
    <div className='jobPost-container'>
    <p className='error-text'>Coming Soon</p>
    </div>
    </>
    
  )
}

export default JobPosting