import React from "react";
import { Routes, Route } from "react-router-dom";
import Registration from './components/auth/Registration'
import Error from './components/error/Error'
import Login from "./components/auth/Logins";
import RegistrationConfirm from "./components/auth/registrationConfirmation";
import OpenRoute from "./components/auth/openRoute";
import VerfiyEmail from "./components/auth/verifyEmail";
import ForgetPassword from "./components/auth/forgetPassword"
import ResetLink from "./components/auth/resetLink";
import DashBoard from './components/recuiterDashboard/DashBoard'
import PrivateRoute from './components/auth/privateRoute'
import VerifyRoute from "./components/auth/Verifyroute";
import UpdatePassword from "./components/auth/updatePassword";
import VerificationExpire from "./components/auth/verificationExpire";
import Credit from './components/recuiterDashboard/CreditSection/Credit';
import Resume from './components/recuiterDashboard/ResumeSection/Resume'
import DashboardLayout from "./components/recuiterDashboard/common/dashBoardLayout";
import AdminDashboardLayout from "./components/adminDashboard/common/AdminDashboardLayout";
import Candidate from "./components/adminDashboard/Candidates/Candidate";
import Recruiters from "./components/adminDashboard/Recuriters/Recruiter";
import Integration from "./components/adminDashboard/Integrations/Integrations";
import AdminDashboard from "./components/adminDashboard/AdminDashboard";
import NotAuthorized from "./components/notAuthorized/NotAuthorized";
import CandidateDetail from "./components/CandidateDetail/CandidateDetail";
import Setting from "./components/adminDashboard/Settings/Setting";
import MarketingDashboardLayout from "./components/marketingDashBoard/common/MarketingDashboardLayout";
import MarketingDashboard from "./components/marketingDashBoard/MarketingDashboard";
import Industries from "./components/adminDashboard/Industries/Industries";
import Skills from "./components/adminDashboard/Skills/Skills";
import Companies from "./components/adminDashboard/Companies/companies";
import CandidateDetailPage from "./components/adminDashboard/Candidates/CandidatePage";
import GoogleAdSense from "./components/adminDashboard/googleAdSense/GoogleAdSense";
import { Navigate } from "react-router-dom";
import LandingPage from "./components/auth/LandingPage/LandingPage";
import ContactUs from "./components/auth/LandingPage/ContactUs";
import UpcomingFeature from "./components/auth/LandingPage/UpcominFeature";
import ComingSoon from "./components/auth/LandingPage/ComingSoon";
import PrivacyPolicy from "./components/auth/LandingPage/PrivacyPolicy";
import TermOfService from "./components/auth/LandingPage/TermOfService";
import License from "./components/auth/LandingPage/License";
import AboutUs from "./components/auth/LandingPage/AboutUs";
import RecruiterPaymentDetail from "./components/adminDashboard/Recuriters/RecrutierPaymentDetail";
import Callback from "./components/auth/common/Callback";
import JobPosting from "./components/recuiterDashboard/JobPosting/JobPosting";
import usePageTracking from "./hooks/UsePageTracking";


function App() {

  usePageTracking()
  
  return (
    <div className="App">
      <Routes>

       <Route path="/" element={<LandingPage />} /> 
      {/* <Route
          path="/"
          element={
            <OpenRoute>
              <LandingPage/>
            </OpenRoute>
          }/> */}
          <Route
          path="/pages/contact-us"
          element={
              <ContactUs/>
          }/>
          <Route
          path="/pages/about-us"
          element={
              <AboutUs/>
          }/>
          <Route
          path="/pages/upcoming-feature"
          element={
              <UpcomingFeature/>
          }/> 
           <Route
          path="/pages/coming-soon"
          element={
              <ComingSoon/>
          }/> 
           <Route
          path="/pages/privacy-policy"
          element={
              <PrivacyPolicy/>
          }/> 
           <Route
          path="/pages/terms-of-service"
          element={
              <TermOfService/>
          }/> 
           {/* <Route
          path="/pages/Licence"
          element={
              <License/>
          }/>  */}
        <Route
          path="/register"
          element={
            <OpenRoute>
              <Registration />
            </OpenRoute>
          }
        />
        <Route
          path="/login"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />
        <Route
          path="/success"
          element={
            <VerifyRoute>
              <RegistrationConfirm />
            </VerifyRoute>
          }
        />
        <Route
          path="/verificationexpire/*"
          element={
            <OpenRoute>
              <VerificationExpire />
            </OpenRoute>
          }
        />
        <Route
          path="/verifyemail/*"
          element={
            <OpenRoute>
              <VerfiyEmail />
            </OpenRoute>
          }
        />
        <Route
          path="/forgetpassword"
          element={
            <OpenRoute>
              <ForgetPassword />
            </OpenRoute>

          }
        />
        <Route
          path="/reset"
          element={
            <VerifyRoute>
              <ResetLink />
            </VerifyRoute>

          }
        />
        <Route
          path="/updatepassword/*"
          element={
            <OpenRoute>
              <UpdatePassword />
            </OpenRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute requiredRole="recruiter">              
            <DashboardLayout/>
            </PrivateRoute>
          }
        >
          {/* <Route index element={<Navigate to="resume" replace />}/> */}
         <Route index element={<DashBoard />} />
          <Route path="credit" element={<Credit />} />
          <Route path="resume" element={<Resume />} />
        <Route path="row-details" element={<CandidateDetail/>} />
        <Route path='job-posting' element={<JobPosting/>}/>
     
        </Route>
        <Route
          path="/admin-dashboard"
          element={
            <PrivateRoute requiredRole="superadmin">
               <AdminDashboardLayout/>
            </PrivateRoute>
             
          }
        >
          <Route index element={<AdminDashboard/>} />
          <Route path="candidates" element={<Candidate/>} />
          <Route path="recuriters" element={<Recruiters/>} />
          <Route path="companies" element={<Companies/>} />
          <Route path="skills" element={<Skills/>} />
          <Route path="industries" element={<Industries/>} />
          <Route path="integrations" element={<Integration/>} />
          <Route path='settings' element={<Setting/>}/>
          <Route path="row-details" element={<CandidateDetailPage/>} />
          <Route path="payment-details" element={<RecruiterPaymentDetail/>} />
          <Route path="ad-sense" element={<GoogleAdSense/>} />
        </Route>
        <Route
          path="/marketing-dashboard"
          element={
            <PrivateRoute requiredRole="marketing">
               <MarketingDashboardLayout/>
            </PrivateRoute>
             
          }
        >
          <Route index element={<MarketingDashboard/>} />
        </Route>
        <Route path="/not-authorized" element={<NotAuthorized/>} />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
